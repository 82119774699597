
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class DashboardService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;

    constructor(private http: HttpClient) { }

     

    listdefaulters(params='') {
        return this.http
        .get<any>(this.RapiUrl + '/dashboard/listdefaulters?'+params).pipe(
        map(res => res));
      }
 

 
     
}
