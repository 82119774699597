
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class SalesreturnService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/srn/list').pipe(
        map(res => res));
      }

      addeditsalesreturn(salesreturn) {
        console.log("service file", salesreturn);
      	return this.http.post(this.apiUrl +'/srn/addeditsalesreturn',salesreturn).pipe(
      	map(res => res));
      }

      
      geteditSalesreturn(id) {
        console.log(id);
         return this.http.get(this.apiUrl+'/srn/editSalesreturn?id='+id).pipe(
         map(res => res)
        
        );
     }

     srnno(branchid){
      return this.http.get(this.apiUrl +'/srn/srnno?branch_id='+branchid).pipe(
        map(res => res));
    }

    getDeliveryinfo(id){
      return this.http.get(this.apiUrl +'/quotation/QuoteallInfo?id='+id).pipe(
        map(res => res));
      
    }
}
