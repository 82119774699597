
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class BatchService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/batch/list').pipe(
        map(res => res));
      }

      addeditbatch(batch) {
        console.log(batch);
      	return this.http.post(this.apiUrl +'/batch/addeditbatch',batch).pipe(
      	map(res => res));
      }

       edit(selectedBatch) {
       console.log(selectedBatch);
      	return this.http.put('',selectedBatch).pipe(
      	map(res => res));
      }

      

      Updatetbatch(batch){
        console.log(batch,"service batch ");
        return this.http.put('',batch).pipe(
          map(res => res)
        );
      }
    
}
