import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';



// const HttpUploadOptions = {
//   headers: new HttpHeaders({ "Accept": "application/json" })
// }
@Injectable({
  providedIn: 'root'
})

export class DeliveryService {
  apiUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) { }

  list() {
        return this.http
        .get<any>(this.apiUrl + '/Quotation/list').pipe(
        map(res => res));
      }

      addeditdelivery(delivery) {
        console.log("Data is to be sent: ",delivery);
      	return this.http.post(this.apiUrl +'/Quotation/Addeditquotation',delivery).pipe(
        map(res => res));
        
      }

      quotdetailsbyid(selectedDeliver) {
       console.log(selectedDeliver);
      	return this.http.get(this.apiUrl +'/Quotation/editQuote?id='+selectedDeliver).pipe(
      	map(res => res));
      }

      challanno(branchid){
        return this.http.get(this.apiUrl +'/Quotation/Challanno?branch_id='+branchid).pipe(
          map(res => res));
      }

      public uploadFile(data) {
        console.log("ts file data",data);
        let uploadURL = this.apiUrl+'/pg/invoiceupload.php';
    
        return this.http.post<any>(uploadURL, data).pipe(
          map(res=> res)
        );
      }

      genLedgerReport(data){
      return this.http.post(this.apiUrl +'/Report/ledgerreport', data).pipe(
        map(res=>res)
      );      
     }
     genProductReport(data){
      return this.http.post(this.apiUrl +'/Report/productreport', data).pipe(
        map(res=>res)
      );      
     }

}
