
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class LedgerService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/ledger/list').pipe(
        map(res => res));
      }

      addeditledger(ledger) {
        console.log(ledger);
      	return this.http.post(this.apiUrl +'/ledger/addeditledger',ledger).pipe(
      	map(res => res));
      }

       edit(selectedLedger) {
       console.log(selectedLedger);
      	return this.http.put('',selectedLedger).pipe(
      	map(res => res));
      }
}
