import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { OwlModule } from 'ngx-owl-carousel';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import {SimpleNotificationsModule } from 'angular2-notifications';
import { AlertService, AuthService, UserService, BranchService,LedgerService,DemandService,SalesreturnService, BatchService, ProductService, CommonService, MasterService, RoleService,DashboardService,DeliveryService} from './services/index';




@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent, 
    BreadcrumbsComponent, 
  ],
  imports: [
    BrowserModule, OwlModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    /*AgmCoreModule.forRoot(  {
      apiKey: 'AIzaSyCRpc8mRnV1coBowtKWw7Jr5MDCMkODbDI',
    libraries: ['places']
    }),*/
    BrowserAnimationsModule,
    AppRoutingModule, 
    SharedModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AlertService, AuthService, UserService,BatchService, ProductService,MasterService, BranchService, LedgerService,DemandService,SalesreturnService, CommonService , RoleService  , DashboardService,DeliveryService
    , MenuItems, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
