
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class DemandService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/Demand/List').pipe(
        map(res => res));
      }

      addeditdemandnote(demandnote) {
        console.log("service file demand data",demandnote);
      	return this.http.post(this.apiUrl +'/Demand/Addeditdemand',demandnote).pipe(
        map(res => res));
      
      }

      editgetProductdetails(id) {
       console.log(id);
      	return this.http.get(this.apiUrl+'/Demand/editDemand?id='+id).pipe(
        map(res => res)
       
       );
    }

    demandno(branchid){
      return this.http.get(this.apiUrl +'/Demand/Demandno?branch_id='+branchid).pipe(
        map(res => res));
    }
}
