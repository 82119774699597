
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from './auth.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { AlertService } from './alert.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public alertService: AlertService,private router: Router,public authService:AuthService) {

  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (!window.navigator.onLine) {
        this.alertService.notify('error', 'Oops!', 'No Internet Connection Detected');
      }

        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    AuthToken: `Bearer ${currentUser.token}`
                }
            });
        }

        return next
        .handle(request).pipe(
        tap((ev: HttpEvent<any>) => {
          //
        }),
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
//            console.log('Processing http error', response.error.error.message);
            if (response.error.error.message) {
            this.alertService.notify('error', 'Oops!', response.error.error.message);
            if(response.error.error.code===401){
              if(localStorage.getItem('token')){
              localStorage.clear();
              location.href='/';
              }
            }
          } else {
              this.alertService.notify('error', 'Oops!', 'Error Occured');
            }
          }
          return observableThrowError(response);
        }),);
    }
}
