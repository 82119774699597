
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class BranchService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/branch/list').pipe(
        map(res => res));
        
      }

      addeditbranch(branch) {
        console.log(branch);
      	return this.http.post(this.apiUrl +'/branch/addeditbranch',branch).pipe(
      	map(res => res ));
      }

       edit(selectedBranch) {
       console.log(selectedBranch);
      	return this.http.put('',selectedBranch).pipe(
      	map(res => res));
      }
}
