import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './components/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'customer',
      //   loadChildren: './components/customer/customer.module#CustomerModule'
      // },
      {
        path: 'user',
        loadChildren: './components/user/user.module#UserModule',
        canActivate: [AuthGuard]
      },
      
      // {
      //   path: 'licensecategory',
      //   loadChildren: './components/licensecategory/licensecategory.module#LicenseCategoryModule'
      // },
      // {
      //   path: 'city',
      //   loadChildren: './components/city/city.module#CityModule'
      // },
      // {
      //   path: 'metamaster',
      //   loadChildren: './components/metamaster/metamaster.module#MetamasterModule'
      // },
      // {
      //   path: 'quotation',
      //   loadChildren: './components/quotation/quotation.module#ApplicationModule',
      //   canActivate: [AuthGuard]
      //   }, {
      //     path: 'creditnote',
      //     loadChildren: './components/creditnote/creditnote.module#CreditnoteModule',
      //     canActivate: [AuthGuard]
      //     },
      { 
        path: 'product',
        loadChildren: './components/product/product.module#ProductModule',
        canActivate: [AuthGuard]
        },
        { 
        path: 'batch',
        loadChildren: './components/batch/batch.module#BatchModule',
        canActivate: [AuthGuard]
        },
      { 
        path: 'branch',
        loadChildren: './components/branch/branch.module#BranchModule',
        canActivate: [AuthGuard]
      },
      
      { 
        path: 'ledger',
        loadChildren: './components/ledger/ledger.module#LedgerModule',
        canActivate: [AuthGuard]
      },
      { 
        path: 'salesreturn',
        loadChildren: './components/salesreturn/salesreturn.module#SalesreturnModule',
        canActivate: [AuthGuard]
      },
      { 
          path: 'demandnote',
          loadChildren: './components/demandnote/demandnote.module#DemandnoteModule',
          canActivate: [AuthGuard]
      },
      { 
        path: 'delivery',
        loadChildren: './components/delivery/delivery.module#DeliveryModule',
        canActivate: [AuthGuard]
    },
  //   { 
  //     path: 'stockreport',
  //     loadChildren: './components/stockreport/stockreport.module#StockreportModule'
      
  // },
      // { 
      //   path: 'license',
      //   loadChildren: './components/license/license.module#LicenseModule'
      // },
      {
        path: 'role',
        loadChildren: './components/role/role.module#RoleModule',
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'transaction',
      //   loadChildren: './components/transaction/transaction.module#TransactionModule',
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'report',
        loadChildren: './components/report/report.module#ReportModule',
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'document',
      //   loadChildren: './components/document/document.module#DocumentModule',
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'incentive',
      //   loadChildren: './components/incentive/incentive.module#IncentiveModule',
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'target',
      //   loadChildren: './components/target/target.module#TargetModule',
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'leadstatus',
      //   loadChildren: './components/leadstatus/leadstatus.module#LeadstatusModule',
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'tracking',
      //   loadChildren: './components/tracking/tracking.module#TrackingModule',
      //   canActivate: [AuthGuard]
      // }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './components/auth/auth.module#AuthModule'
      },/*
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      }*/
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
