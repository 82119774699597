import { filter } from 'rxjs/operators';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';
import swal from 'sweetalert2';
declare var jQuery:any;

@Injectable()
export class CommonService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient, public servicePNotify: AlertService,) { }

    isDevInstance(){
      if(this.apiUrl.includes('dev')){
        return true;
      } else {
        return false;
      }
    }

    cloneWR(p){
      return JSON.parse(JSON.stringify(p));
    }


    showModal(id:any){
       this.openModal(id);
    }
     

    openModal(event) {
       document.querySelector('#' + event).classList.add('md-show');
    }

    sa(m1,m2:any,m3:any){
        swal(m1,m2,m3);

    }

    windowpopup(path='',width=999,height=700){
      window.open(path, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=50,left=200,width='+width+',height='+height);
    }

    hideModal(event=''){
      document.querySelector('#' + event).classList.remove('md-show');
      if(event ==='0')
      {
     // jQuery(".modal").modal("hide");
      }
      else {
     //   jQuery("#"+id).modal("hide");

      }
    }


    notify(type, title, msg= null) {
      this.servicePNotify.notify(type, title, msg);
    }

    getCurrentUser(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }
    getCurrentRole(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }
    getCurrentProduct(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }
    getCurrentGodown(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }
    getCurrentRegion(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }
    getCurrentLedger(){
      return JSON.parse(localStorage.getItem('currentUser'));
    }

    // getCurrentGodown(){
    //   return JSON.parse(localStorage.getItem('currentUser'));
    // }

    field_validate($event,cf,$object){
      let regexp:any;
       
      let val:any=$event.target.value;
      if(val.length < 1){return 0;}

          switch(cf){

            case 'mobile':
            regexp= /^([0-9]){10}?$/;
            console.log(regexp.test(val));
            if(regexp.test(val)){

            } else {
              $event.target.value='';
              $object='';
              this.notify('error','Invalid Mobile Number');
            }
            break;

             case 'pan':
              regexp= /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
              console.log(regexp.test(val));
              if(regexp.test(val)){

              } else {
                $event.target.value='';
                $object='';
                this.notify('error','Invalid PAN Number');
              }
             break;

             case 'gst':
             regexp= /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z1-9]){1}([a-zA-Z0-9]){1}$/;

             if(regexp.test(val)){
          
             } else {
              $event.target.value='';
              $object='';
               this.notify('error','Invalid GST Number');
             }
            break;

            case 'tan':
            
            regexp= /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/;
             if(regexp.test(val)){
          
             } else {
              $event.target.value='';
              $object='';
               this.notify('error','Invalid TAN Number');
             }
            break;


          }
    }

   
    getBranchbyid() {
      return this.http
      .get<any>(this.apiUrl + '/branch/list').pipe(
      map(res => res));
      
    }

}
