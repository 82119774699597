
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class ProductService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/product/list').pipe(
        map(res => res));
      }

      addeditproduct(product) {
        console.log(product);
      	return this.http.post(this.apiUrl +'/product/addeditproduct',product).pipe(
        map(res => res   
          ));
      }

       edit(selectedProduct) {
       console.log(selectedProduct);
      	return this.http.put('',selectedProduct).pipe(
      	map(res => res));
      }
}
