
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class RoleService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/role/list').pipe(
        map(res => res));
      }

    listPermissions(){
        return this.http
        .get<any>(this.apiUrl + '/role/listpermissions').pipe(
        map(res => res));
    }  
    
       addEdit(role) {
        console.log(role);
           return this.http.post(this.apiUrl + '/role/addedit',role).pipe(
           map(res => res));
       }

       search(term : any='0') {
        return this.http
        .get<any>(this.apiUrl + '/role/search?q='+term).pipe(
        map(res => res));
      }
}
